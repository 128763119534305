import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Link from 'next/link';
// Components
import Layout from '../components/common/Layout';
import useScreenSize from '../components/customHooks/useScreenSize';

const NotFound = () => {
  const screenSize = useScreenSize();
  const router = useRouter();
  const asPath = router.query.path;

  return (
    <Layout isHomePage={false} serverUserData={{}}>
      <section
        className={
          'container--notfoundpage ' +
          (screenSize === 'largeScreen'
            ? 'banner-fullHeight'
            : 'banner-shortHeight')
        }
      >
        <svg
          className="skytop"
          viewBox="0 0 1571 265"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-1.46095 164.631C126.731 69.3217 499.303 -85.3114 964.057 58.6311C1363.66 182.395 1505.58 208.934 1537.51 206.456L1545 203.226C1545 204.877 1542.72 206.052 1537.51 206.456L1401.77 265L-26 234.557L-1.46095 164.631Z"
            fill="#E67C89"
            fillOpacity="0.22"
          />
        </svg>
        <svg
          className="skybottom"
          viewBox="0 0 1571 341"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M729.225 15.8431C341.114 -48.4956 51.3619 98.5976 -45 180.186V340.534H261.568H729.225H1488.5L1579 180.186C1334.24 204.163 1214.36 96.2665 729.225 15.8431Z"
            fill="#E0E6FC"
          />
        </svg>
        <div className="cloudleft-container">
          <svg
            className="cloudleft"
            width="257"
            height="104"
            viewBox="0 0 257 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M82.6668 0C100.418 0 115.786 10.2024 123.227 25.0632C131.128 15.2026 143.271 8.88828 156.889 8.88828C177.349 8.88828 194.48 23.1404 198.895 42.2581C205.041 36.9727 213.036 33.7782 221.778 33.7782C241.169 33.7782 256.889 49.4979 256.889 68.8893C256.889 86.0011 244.648 100.254 228.445 103.368V103.999H222.031C221.947 104 221.862 104 221.778 104C221.693 104 221.609 104 221.525 103.999H34.4543C34.377 104 34.2996 104 34.2222 104C34.1448 104 34.0674 104 33.9901 103.999H26.6669V103.163C11.4018 99.7236 0 86.0823 0 69.778C0 50.8776 15.3218 35.5558 34.2222 35.5558C35.615 35.5558 36.9883 35.639 38.3375 35.8007C42.716 15.341 60.8999 0 82.6668 0Z"
              fill="white"
            />
          </svg>
          <svg
            className="sadface"
            width="68"
            height="43"
            viewBox="0 0 68 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9.13774" cy="9.13774" r="9.13774" fill="#585858" />
            <circle cx="58.847" cy="9.13774" r="9.13774" fill="#585858" />
            <path
              d="M15.3513 41.0001C20.834 28.8164 36.0392 11.7593 52.9988 41.0001"
              stroke="#585858"
              strokeWidth="4"
            />
            <circle cx="12.7929" cy="12.7929" r="1.82755" fill="white" />
            <circle cx="63.2331" cy="12.7929" r="1.82755" fill="white" />
          </svg>
          <div className="sadface-container"></div>
        </div>
        <svg
          className="cloudcenter"
          width="131"
          height="53"
          viewBox="0 0 131 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.1283 0C51.1744 0 59.0062 5.19928 62.7984 12.7726C66.8247 7.74747 73.0131 4.52961 79.9532 4.52961C90.3797 4.52961 99.1098 11.7927 101.36 21.5354C104.492 18.8419 108.567 17.2139 113.021 17.2139C122.904 17.2139 130.915 25.2249 130.915 35.107C130.915 43.8275 124.676 51.0909 116.419 52.6781V52.9997H113.151C113.108 53 113.065 53.0002 113.021 53.0002C112.978 53.0002 112.935 53 112.892 52.9997H17.5591L17.4911 53.0001L17.4402 53.0001C17.4005 53.0001 17.3609 53 17.3213 52.9997H13.5898V52.5737C5.81051 50.8207 0 43.8689 0 35.56C0 25.928 7.80823 18.1198 17.4402 18.1198C18.1499 18.1198 18.8498 18.1622 19.5374 18.2446C21.7687 7.81801 31.0355 0 42.1283 0Z"
            fill="white"
          />
        </svg>
        <svg
          className="cloudright"
          width="186"
          height="76"
          viewBox="0 0 186 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.8547 0C72.7073 0 83.8345 7.38708 89.2223 18.1471C94.9427 11.0077 103.735 6.43599 113.595 6.43599C128.409 6.43599 140.812 16.755 144.009 30.5969C148.459 26.7699 154.248 24.4567 160.578 24.4567C174.618 24.4567 186 35.8386 186 49.8789C186 62.2687 177.137 72.5884 165.405 74.8434V75.301H160.578H24.7785H19.308V74.6951C8.25535 72.2045 0 62.3275 0 50.5225C0 36.8377 11.0937 25.7439 24.7785 25.7439C25.7869 25.7439 26.7813 25.8042 27.7581 25.9213C30.9285 11.1075 44.0944 0 59.8547 0Z"
            fill="white"
          />
        </svg>
        <svg
          className="bottomcloud"
          viewBox="0 0 1440 451"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M493 240C493.718 240 494.435 239.994 495.15 239.981C509.14 333.378 589.706 405 687 405C778.543 405 855.278 341.594 875.682 256.304C895.413 253.935 913.323 245.643 927.582 233.257C945.016 259.046 974.528 276 1008 276C1011.28 276 1014.52 275.838 1017.71 275.52C1037.3 333.366 1092.04 375 1156.5 375C1169.28 375 1181.68 373.363 1193.5 370.288C1228.72 419.174 1286.14 451 1351 451C1458.14 451 1545 364.143 1545 257C1545 149.857 1458.14 63 1351 63C1306.43 63 1265.37 78.0303 1232.61 103.299C1210.43 89.7847 1184.37 82 1156.5 82C1125.77 82 1097.25 91.4618 1073.69 107.631C1056.41 91.7181 1033.34 82 1008 82C983.677 82 961.444 90.9528 944.418 105.743C926.984 79.9539 897.472 63 864 63C849.189 63 835.152 66.3197 822.595 72.2557C787.62 38.0698 739.771 17 687 17C650.267 17 615.918 27.2092 586.636 44.945C564.644 17.5427 530.87 0 493 0C443.885 0 401.663 29.5062 383.09 71.7594C369.169 66.111 353.948 63 338 63C308.004 63 280.579 74.0054 259.542 92.1987C224.333 56.283 175.269 34 121 34C13.8568 34 -73 120.857 -73 228C-73 335.143 13.8568 422 121 422C203.63 422 274.195 370.34 302.158 297.556C313.477 301.094 325.516 303 338 303C387.115 303 429.337 273.494 447.91 231.241C461.831 236.889 477.052 240 493 240Z"
            fill="white"
          />
        </svg>
        <div
          className={
            'container--notfoundpage_wrapper' +
            (screenSize == 'largeScreen' ? '' : ' shortHeight')
          }
        >
          <h2>404</h2>
          <p>Oops, the page you&apos;re looking for does not exist!</p>
          <Link href="/" legacyBehavior>
            <button>Back to Home</button>
          </Link>
        </div>
      </section>
    </Layout>
  );
};

NotFound.propTypes = {
  serverUserData: PropTypes.object,
};

export default NotFound;
